import React, { useState } from "react"
import { useCasinoResultQuery } from "../../../../store/service/casino/casinoServices"
import { LetterAndColorById } from "../Constant"
import { useParams } from "react-router-dom"
import ResultModalContainer from "./ResultModalContainer"

interface Props {
  matchId: any
  casinoName: any
}

const LastResult = ({ matchId, casinoName }: Props) => {
  const [first, setFirst] = useState("");
  const [openMod, setOpenMod] = useState(false);
  const { id } = useParams()
  const { data: resultList } = useCasinoResultQuery(casinoName, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 5000,
  })

  const handleClick = val => {
    setFirst(val)
    if (val) {
      setOpenMod(true)
    }
  }
  return (
    <>
      <ResultModalContainer
       setOpen={setOpenMod} open={openMod} tableId ={id} mid={first}
      />
      <div className="last-results-section_">
        <div className="last-result-header_">
          <div className="last-result-heading_">Last Result</div>
          <div className="last-result-view-all_">View All</div>
        </div>
        <div className="last-result-container_">
          {resultList?.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className="last-result-box_ A"
                  style={{
                    background: LetterAndColorById[id]?.[item.result]?.color,
                  }}
                  onClick={() => handleClick(item.mid)}
                >
                  {LetterAndColorById[id]?.[item.result]?.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default LastResult
