import type React from "react"

interface AAAProps {
  t1: any
  odds: any
  setBetState: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AAA = ({ t1, odds, setBetState, setOpen }: AAAProps) => {
  const t2 = odds?.t2 || []

  const handleClick = (t2Data: any, isBack: boolean) => {
    setBetState &&
      setBetState((prev: any) => ({
        ...prev,
        nation: t2Data?.nation,
        casinoName: 2,
        isBack,
        odds: isBack ? Number(t2Data?.b1) : Number(t2Data?.l1),
        selectionId: t2Data?.sid,
        colorName: "back",
        matchId:"56"
      }))
    setOpen(true)
  }

  console.log(t2, "t2t2")

  return (
    <div className="aaa-odds-section_">
      <div className="aaa-odds-container_ aaa-groups-odds-container_">
        <div className="aaa-odds-box_ aaa-box_">
          <div className="aaa-box-label_">A.{t2?.[0]?.nation}</div>
          <div className="aaa-two-gropu-boxes_">
            <div
              onClick={() => handleClick(t2?.[0], true)}
              className={`aaa-odds-group-box_ back_ ${t2?.[0]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[0]?.b1}{" "}
            </div>
            <div
              onClick={() => handleClick(t2?.[0], false)}
              className={`aaa-odds-group-box_ back_ ${t2?.[0]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[0]?.l1}{" "}
            </div>
          </div>
          <div className={`aaa-group-position_ ${t2?.[0]?.pnl>=0?"plus":"minus"}`}> {t2?.[0]?.pnl}</div>
        </div>
        <div className="aaa-odds-box_ aaa-box_">
          <div className="aaa-box-label_">B.{t2?.[1]?.nation}</div>
          <div className="aaa-two-gropu-boxes_">
            <div
              onClick={() => handleClick(t2?.[1], true)}
              className={`aaa-odds-group-box_ back_ ${t2?.[1]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[1]?.b1}{" "}
            </div>
            <div
              onClick={() => handleClick(t2?.[1], false)}
              className={`aaa-odds-group-box_ back_ ${t2?.[1]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[1]?.l1}{" "}
            </div>
          </div>
          <div className={`aaa-group-position_ ${t2?.[1]?.pnl>=0?"plus":"minus"}`}> {t2?.[1]?.pnl}</div>
        </div>
        <div className="aaa-odds-box_ aaa-box_">
          <div className="aaa-box-label_">C.{t2?.[2]?.nation}</div>
          <div className="aaa-two-gropu-boxes_">
            <div
              onClick={() => handleClick(t2?.[2], true)}
              className={`aaa-odds-group-box_ back_ ${t2?.[2]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[2]?.b1}{" "}
            </div>
            <div
              onClick={() => handleClick(t2?.[2], false)}
              className={`aaa-odds-group-box_ back_ ${t2?.[2]?.gstatus != "ACTIVE" ? "suspended" : ""}`}
            >
              {" "}
              {t2?.[2]?.l1}{" "}
            </div>
          </div>
          <div className={`aaa-group-position_ ${t2?.[2]?.pnl>=0?"plus":"minus"}`}> {t2?.[2]?.pnl}</div>
        </div>
        {/**/}
      </div>
    </div>
  )
}

export default AAA
