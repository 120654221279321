/* eslint-disable jsx-a11y/iframe-has-title */
import "./video.scss"
import { useParams } from "react-router-dom"
import TeenPattiCard from "./TeenPattiCard"
import DTCard from "./DTCard"
import Lucky7Card from "./Lucky7Card"

const VodeoSection = ({ t1, t3 }: any) => {
  const { id, tableId } = useParams()

  const videoData: Record<string, string> = {
    "52": "3035",
    "55": "3041",
    "53": "3058",
    "54": "3056",
    "51": "3030",
    "61": "3047",
    "60": "3053",
  }
  return (
    <div className="teenpatti-live-tv-box_">
      <iframe
        width="100%"
        height={210}
        style={{
          border: 1,
          marginLeft: "-1px",
          marginRight: "-1px",
          marginTop: 0,
          marginBottom: "-5px",
        }}
        src={`https://alpha-n.qnsports.live/route/raj.php?id=${videoData[id]}`}
      />

      <div className="flip-clock">
        <div id="tens" className="digits-group">
          <div className="left_">{t1.autotime}</div>
        </div>
      </div>
      {id === "51" && <TeenPattiCard t1={t1} />}
      {id === "52" && <DTCard t1={t1} />}
      {(id === "53" || id === "54") && <Lucky7Card t1={t1} />}
    </div>
  )
}

export default VodeoSection
