import { useEffect, useState } from "react"
import "./login.scss"
import { useNavigate } from "react-router-dom"
import { useLoginMutation } from "../../../store/service/authService"
import snackbarUtil from "../../utils/Snackbar"

const NewLoginPage = () => {
  const [clientCode, setClientCode] = useState("")
  const [password, setPassword] = useState("")
  const token = localStorage.getItem("client-token")

  const [errors, setErrors] = useState({
    clientCode: "",
    password: "",
  })
  const nav = useNavigate()
  const [trigger, { data, isLoading }] = useLoginMutation()

  const handleSubmit = (event: any) => {
    event.preventDefault()
    let formErrors = { clientCode: "", password: "" }

    if (!clientCode) {
      snackbarUtil.error("UserId is required")
    }
    if (!password) {
      snackbarUtil.error("Password is required")
    }

    setErrors(formErrors)

    if (!formErrors.clientCode && !formErrors.password) {
      trigger({
        password: password,
        url: window.location.hostname,
        //  url: "fastbet365.in",
        userId: "C" + clientCode,
      })
    }
  }

  useEffect(() => {
    if (data) {
      if (data?.token) {
        nav("/tc")
        localStorage.setItem("client-token", data?.token)
        localStorage.setItem("userId", data?.userId)
        localStorage.setItem("welShow", "true")
        snackbarUtil.success("Success")
      } else {
        snackbarUtil.error(data?.message)
      }
    }
  }, [data, nav])

  useEffect(() => {
    if (token) {
      nav("/main")
    }
  }, [token])

  return (
    <div className="login-page-section_">
      <div className="login-page-container_">
        <div className="login-box_">
          <form className="login-form_" onSubmit={handleSubmit}>
            <div className="login-heading_">Log in</div>
            <div className="login-input-group_">
              <div className="input-wrapper">
                <span className="input-addon">C</span>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Username"
                  value={clientCode}
                  onChange={e => setClientCode(e.target.value)}
                  className="login-input-field_ username-input-field_   "
                />
              </div>
            </div>
            <div className="login-input-group_">
              <input
                type="password"
                autoComplete="off"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="login-input-field_ password-input-field_"
              />
            </div>
            <div className="login-action-btn_">
              <button
                className="sign-me-btn_"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loader_login"></div>
                ) : (
                  <span className="">Sign me</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <p className="copyRight">
        Copyright ©{window.location.hostname?.split(".")[0]} 2025
      </p>
    </div>
  )
}

export default NewLoginPage
