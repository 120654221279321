import type React from "react"

interface Lucky7Props {
  t1: any
  odds: any
  setBetState: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Lucky7 = ({ t1, odds, setBetState, setOpen }: Lucky7Props) => {
  const t2 = odds?.t2 || []

  const handleClick = (t2Data: any) => {
    setBetState &&
      setBetState((prev: any) => ({
        ...prev,
        nation: t2Data?.nation,
        casinoName: 2,
        isBack: true,
        odds: Number(t2Data?.rate),
        selectionId: t2Data?.sid,
        colorName: "back",
      }))
    setOpen(true)
  }

  return (
    <div className="lucky7-odds-section_">
      <div className="lucky7-odds-container_ low-high-cards-container_">
        <div className="lucky7-odds-box_ high-low-card">
          <div className="odds-price_">{t2?.[0]?.rate}</div>
          <div
            className={`odds-label_ ${!t2?.[0]?.gstatus ? "suspended" : ""}`}
            onClick={() => t2?.[0]?.gstatus && handleClick(t2?.[0])}
          >
            {t2?.[0]?.nation}
          </div>
          <div
            className={`odds-position_  ${t2?.[0]?.pnl >= 0 ? "plus" : "minus"}`}
          >
            {t2?.[0]?.pnl}
          </div>
        </div>
        <div className="low-high-card-image_">
          <img src="/img/card7.jpg" alt="" className="card-image_" />
        </div>
        <div className="lucky7-odds-box_ high-low-card">
          <div className="odds-price_">{t2?.[1]?.rate}</div>
          <div
            className={`odds-label_ ${!t2?.[1]?.gstatus ? "suspended" : ""}`}
            onClick={() => t2?.[1]?.gstatus && handleClick(t2?.[1])}
          >
            {t2?.[1]?.nation}
          </div>
          <div
            className={`odds-position_  ${t2?.[1]?.pnl >= 0 ? "plus" : "minus"}`}
          >
            {t2?.[1]?.pnl}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lucky7
