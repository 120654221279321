import { Box, Grid } from "@mui/material"
import CardComp from "../CardComp/CardComp"
import { FaTrophy } from "react-icons/fa"

const ResulTModalContent3Card = ({ result }) => {
  const cardArr = result[0]?.cards?.split(",")
  return (
    <div className="casino-result-modal">
      <div className="casino-result-round-id">
        <span style={{ fontSize: "14px" }}>
          <b style={{ fontSize: "17px" }}>Round Id: </b> {result[0]?.mid}
        </span>
        {/* <span>
          <b>Match Time: </b>14/08/2024 01:03:29 PM
        </span> */}
      </div>
      {/* <div className="w-100 text-end">
        Round Id: {result[0]?.mid?.split(".")[1]}
      </div> */}
      <Grid container>
        <Grid xs={12} md={6}>
          <div className=" three-card-result-container">
            <div className="text-center">Player A</div>
            <div className=" three-card-result">
              <CardComp shown={true} card={cardArr[0]} />
              <CardComp shown={true} card={cardArr[2]} />
              <CardComp shown={true} card={cardArr[4]} />
              {result[0]?.win === "1" && (
                <FaTrophy className="tropth_win trophyIcon" />
              )}
            </div>
            <div></div>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div className=" three-card-result-container">
            <div className="text-center">Player B</div>
            <div className="three-card-result">
              <CardComp shown={true} card={cardArr[1]} />
              <CardComp shown={true} card={cardArr[3]} />
              <CardComp shown={true} card={cardArr[5]} />
              <div>
                {result[0]?.win !== "1" && (
                  <FaTrophy className="tropth_win trophyIcon trophyIcon1" />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Box>
        <Grid container justifyContent={"center"}>
          <Grid xs={12} md={6}>
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner: </div>
                <div className="font_bold">
                  {result[0]?.win === "1" ? "Player A" : "Player B"}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default ResulTModalContent3Card
