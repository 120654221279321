import "./mybet.scss"
import { useGetCasinoMyBetQuery } from "../../../../store/service/userServices/userServices"
import { useNavigate } from "react-router-dom"
import CommonLodding from "../../../Component/CommonLodding"

interface Props {
  id: string
}

const MybetCasino = ({ id }: Props) => {
  const nav = useNavigate()
  const { data: betList, isLoading } = useGetCasinoMyBetQuery(
    { tableId: id, isGameCompleted: false, sportId: 5015 },
    { pollingInterval: 1500, refetchOnMountOrArgChange: true },
  )

  return (
    <>
      <div className="bets-history-section_">
        <div className="bets-table-section_">
          <div className="my-bets-heading_">MY BET</div>
          <div className="bets-table_">
            <table className="table_">
              <thead className="thead_">
                <tr>
                  <th className="bet-history-td">Round Id</th>
                  <th className="bet-history-td">Nation</th>
                  <th className="bet-history-td">Odds</th>
                  <th className="bet-history-td">Mode</th>
                  <th className="bet-history-td">Amount</th>
                </tr>
              </thead>
              <tbody>
                {betList?.data?.map(items => {
                  return (
                    <tr>
                      <td>{items?.roundId}</td>
                      <td>{items?.selectionName}</td>
                      <td>{items?.odds}</td>
                      <td>{items?.back ? "L" : "K"}</td>
                      <td>{items?.stake}</td>
                    </tr>
                  )
                })}
              </tbody>
              {/**/}
            </table>
            {/**/}
          </div>
          <div className="see-all-btn-wrapper_">
            <button
              onClick={() => nav("/casino/casino-bet")}
              className="see-all-btn_"
              tabIndex={0}
            >
              See All Complete Bets{" "}
            </button>
          </div>
        </div>
      </div>
      {isLoading && <CommonLodding />}
    </>
  )
}

export default MybetCasino
