import "./casino.scss"
import LastResult from "./LastResult/LastResult"
import VodeoSection from "./VideoSection/VodeoSection"
import CasinoHead from "./CasinoHead/CasinoHead"
import BackBtn from "../../Component/BackBtn/BackBtn"
import TeenPatti from "./TeenPatti/TeenPatti"
import { useOdds } from "./UseOdds/UseOdds"
import { tableIdtoUrl } from "./Constant"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import moment from "moment"
import CasinoBetPlace from "./CasinoBetPlace/CasinoBetPlace"
import MybetCasino from "./Mybet/MybetCasino"
import DT20 from "./DT20/DT20"
import Lucky7 from "./Lucky7/Lucky7"
import AAA from "./AAA/AAA"
import AndarBhar from "./AndarBhar/AndarBhar"

const CasinoMainPage = () => {
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  var curr = new Date()
  curr.setDate(curr.getDate() + 3)
  const pTime = moment(curr).format("YYYY-MM-DD HH:mm:ss.SSS")
  const [betState, setBetState] = useState({
    nation: "",
    casinoName: 0,
    isBack: true,
    odds: null,
    marketId: "",
    placeTime: pTime,
    selectionId: null,
    colorName: "",
    stake: "",
    matchId: id,
    deviceInfo: {
      userAgent:
        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
      browser: "Chrome",
      device: "Macintosh",
      deviceType: "desktop",
      os: "Windows",
      os_version: "windows-10",
      browser_version: "108.0.0.0",
      orientation: "landscape",
    },
  })

  const { odds } = useOdds(tableIdtoUrl[id])
  const t1 = odds?.t1?.[0]

  useEffect(() => {
    setBetState(prev => ({
      ...prev,
      marketId: t1?.mid,
    }))
  }, [t1?.mid])

  if (odds === null) {
    return
  }

  return (
    <div className="teenpatti-sction_" style={{ position: "relative" }}>
      <div className="teenpatti-container_ ">
        <div className="teenpatti-game-screen_">
          <div className="teenpatti-game-box_">
            {open && (
              <CasinoBetPlace
                setBetState={setBetState}
                betState={betState}
                setOpen={setOpen}
              />
            )}
            <BackBtn name="BACK TO CASINO MENU" to="/casino" />
            <div style={{ marginTop: "3px" }}>
              <CasinoHead t1={t1} />
              {odds?.t1 && (
                <VodeoSection
                  t3={odds && odds?.length !== 0 && odds.t3}
                  t1={odds && odds?.length !== 0 && odds?.t1?.[0]}
                />
              )}

              {id === "51" && (
                <TeenPatti
                  t1={t1}
                  odds={odds}
                  setBetState={setBetState}
                  setOpen={setOpen}
                />
              )}
              {id === "52" && (
                <DT20
                  t1={t1}
                  odds={odds}
                  setBetState={setBetState}
                  setOpen={setOpen}
                />
              )}
              {id === "53" && (
                <Lucky7
                  t1={t1}
                  odds={odds}
                  setBetState={setBetState}
                  setOpen={setOpen}
                />
              )}
              {id === "54" && (
                <AAA
                  t1={t1}
                  odds={odds}
                  setBetState={setBetState}
                  setOpen={setOpen}
                />
              )}
              {id === "60" && (
                <AndarBhar
                  t1={t1}
                  odds={odds}
                  setBetState={setBetState}
                  setOpen={setOpen}
                />
              )}

              <LastResult matchId={t1?.mid[1]} casinoName={tableIdtoUrl[id]} />
            </div>
          </div>
        </div>
        <MybetCasino id={id} />
      </div>
    </div>
  )
}

export default CasinoMainPage
