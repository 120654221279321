import React from "react"
import CardComp from "../CardComp/CardComp"

const TeenPattiCard = ({ t1 }: any) => {
  return (
    <div className="card-shower_">
      <div className="runners_">
        <div className="runner_">
          <div className="runner-name_ ">Player A</div>
          <div className="runner-cards_">
            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C1 != "1"} card={t1.C1 || "1"} />
            </div>

            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C2 != "1"} card={t1.C2 || "1"} />
            </div>

            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C3 != "1"} card={t1.C3 || "1"} />
            </div>
          </div>
        </div>
        <div className="runner_ ">
          <div className="runner-name_">Player B</div>
          <div className="runner-cards_">
            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C4 != "1"} card={t1.C4 || "1"} />
            </div>

            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C5 != "1"} card={t1.C5 || "1"} />
            </div>

            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C6 != "1"} card={t1.C6 || "1"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeenPattiCard
