import React from "react"
import { Navigate, Outlet } from "react-router-dom"

const ProtectedRoute = () => {
  const token = localStorage.getItem("client-token")

  if (!token) {
    return <Navigate to="/" replace />
  }
  return <Outlet />
}

export default ProtectedRoute
