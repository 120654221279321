import { useEffect } from "react"
import { useGetIpfyQuery } from "../../../../store/service/odds/oddsServices"
import { useGetCasinoBetPlacedMutation } from "../../../../store/service/userServices/userServices"
import "./style.scss"
import snackbarUtil from "../../../utils/Snackbar"

interface Props {
  setBetState: any
  betState: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CasinoBetPlace = ({ setBetState, betState, setOpen }: Props) => {
  const [trigger, { data, isLoading }] = useGetCasinoBetPlacedMutation()
  const { data: userIp } = useGetIpfyQuery()

  const handleStakeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value) || 0
    setBetState((prev: any) => ({
      ...prev,
      stake: value,
    }))
  }

  const handleStakeButtonClick = (amount: number) => {
    setBetState((prev: any) => ({
      ...prev,
      stake: amount,
    }))
  }

  const handleCasinoBetPlaced = () => {
    trigger({
      ...betState,
      userIp: userIp?.ip,
    })
    setBetState(prev => ({
      ...prev,
      stake: 0,
    }))
  }

  useEffect(() => {
    if (data) {
      if (!data?.status) {
        snackbarUtil.error(data?.message)
      } else {
        snackbarUtil.success(data?.message)
        setOpen(false)
      }
    }
  }, [data])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="bet-card_">
      <div className="bet-card-container_">
        <div className="bet-card-header_">
          <div className="header-right_">
            <div className="nation_">Nation: Player A</div>
          </div>
          <div className="header-left_" />
        </div>
        <div className="bet-card-inputs_">
          <div className="input-group_ odds-input-group_">
            <div className="input-label_">Odds</div>
            <input
              readOnly
              type="text"
              value={betState?.odds}
              className="input-field_ odds-input"
            />
          </div>
          <div className="input-group_">
            <div className="input-label_">Stake</div>
            <input
              type="number"
              className="input-field_ stake-input_ "
              value={betState?.stake}
              onChange={handleStakeChange}
            />
          </div>
          <div className="input-group_">
            <div className="input-label_" />
            <button className="form-clear-btn_">Clear</button>
          </div>
        </div>
        <div className="stakes-container_">
          <div className="stakes-container_">
            {[100, 200, 500, 1000, 5000, 10000, 25000, 50000].map(amount => (
              <button
                key={amount}
                className="stake-btn_"
                onClick={() => handleStakeButtonClick(amount)}
              >
                {amount}
              </button>
            ))}
          </div>
          {/**/}
        </div>
        <div className="bet-card-form-actions_">
          <button className="form-action-btn_ cancel_" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="form-action-btn_ submit_"
            onClick={handleCasinoBetPlaced}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default CasinoBetPlace
