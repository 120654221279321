export const titleById = {
    "51": "20-20 Teenpatti",
    "52": "20-20 DRAGON TIGER",
    "53": "LUCKY 7 - A",
    "54": "AAA",
    "55": "BOLLYWOOD TABLE",
    "57": "1 DAY TEENPATTI",
    "60": "ANDAR BAHAR",
    "61": "20-20 D T L",
  };
  
  export const CardTypeByAbrv = {
    DD: "eat",
    HH: "pan",
    CC: "cdee",
    SS: "hukum",
  };
  
  export const nameById = {
    "51": "tp20DataBig",
    "52": "dt20DataBig",
    "53": "lucky7euDataBig",
    "54": "aaaDataBig",
    "55": "btDataBig",
    "56": "opentpDataBig",
    "57": "1daytpDataBig",
    "58": "1daypDataBig",
    "59": "2020pDataBig",
    "60": "ab20DataBig",
    "61": "1daydtDataBig",
  };
  export const videoIdById = {
    "51": "3030",
    "52": "3035",
    "53": "3032",
    "54": "3056",
    "55": "3041",
    "56": "3049",
    "57": "3031",
    "58": "3051",
    "59": "3052",
    "60": "3053",
    "61": "3057",
  };
  
  export const tableIdtoUrl = {
    "51": "teen20",
    "52": "dt20",
    "53": "lucky7",
    "54": "aaa",
    "55": "btable",
    "56": "teen8",
    "57": "Teen",
    "58": "poker",
    "59": "poker20",
    "60": "ab20",
    "61": "dtl20",
  };
  export const idFromGtype = {
    "51": "teen20",
    "53": "lucky7eu",
    "54": "aaa",
    "55": "btable",
  };
  
  
  const redColorForLabel = "#e91100";
  const yellowColorForLabel = "#004e92";
  const blueColorForLabel = "black";
  const whiteColorForLabel = "#fff";
  const greenColorForLabel = "#368011";
  
  
  export const LetterAndColorById = {
  "51": {
    "1": {
      color: greenColorForLabel,
      label: "A",
    },
    "2": {
      color: greenColorForLabel,
      label: "A",
    },
    "3": {
      color: redColorForLabel,
      label: "B",
    },
    "4": {
      color: redColorForLabel,
      label: "B",
    },
  },
  "52": {
    "1": {
      color: greenColorForLabel,
      label: "D",
    },
    "2": {
      color: redColorForLabel,
      label: "T",
    },
    "3": {
      color: yellowColorForLabel,
      label: "Tie",
    },
  },
  "53": {
    "1": {
      color: redColorForLabel,
      label: "L",
    },
    "2": {
      color: greenColorForLabel,
      label: "H",
    },
    "0": {
      color: blueColorForLabel,
      label: "T",
    },
  },
  "54": {
    "1": {
      color: greenColorForLabel,
      label: "A",
    },
    "2": {
      color: redColorForLabel,
      label: "B",
    },
    "3": {
      color: yellowColorForLabel,
      label: "C",
    },
  },
  "55": {
    "1": {
      color: yellowColorForLabel,
      label: "A",
    },
    "2": {
      color: yellowColorForLabel,
      label: "B",
    },
    "3": {
      color: yellowColorForLabel,
      label: "C",
    },
    "4": {
      color: yellowColorForLabel,
      label: "D",
    },
    "5": {
      color: yellowColorForLabel,
      label: "E",
    },
    "6": {
      color: yellowColorForLabel,
      label: "F",
    },
  },
  "56": {
    "0": {
      color: yellowColorForLabel,
      label: "R",
    },
  },
  "57": {
    "1": {
      color: redColorForLabel,
      label: "A",
    },
    "2": {
      color: yellowColorForLabel,
      label: "B",
    },
  },
  "58": {
    "11": {
      color: redColorForLabel,
      label: "A",
    },
    "21": {
      color: yellowColorForLabel,
      label: "B",
    },
    "0": {
      color: whiteColorForLabel,
      label: "T",
    },
  },
  "59": {
    "11": {
      color: redColorForLabel,
      label: "A",
    },
    "21": {
      color: yellowColorForLabel,
      label: "B",
    },
    "0": {
      color: whiteColorForLabel,
      label: "T",
    },
  },
  "60": {
    "0": {
      color: yellowColorForLabel,
      label: "R",
    },
  },
  "61": {
    "1": {
      color: redColorForLabel,
      label: "D",
    },
    "21": {
      color: yellowColorForLabel,
      label: "T",
    },
    "41": {
      color: blueColorForLabel,
      label: "L",
    },
  },
  };