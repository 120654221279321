import React, { useState } from "react"
import { titleById } from "../Constant"
import { useParams } from "react-router-dom"
import RuleModals from "./RuleModals"

const CasinoHead = ({ t1 }: any) => {
  const { id } = useParams();
  const [show, setShow] = useState(false)
  return (
    <div className="teenpatti-game-header_" >
      <div className="game-type_">{titleById[id]}</div>
      <div className="game-round-id_">
        Round Id : {t1?.mid}
        <span title="Info" className="fas fa-info-circle" onClick={()=>setShow(!show)}/>
      </div>
     {show && <RuleModals id={id} setShow={setShow} show={show}/>}
    </div>
  )
}

export default CasinoHead
