import { titleById } from "../Constant"
import "./style.scss"

const RuleModals = ({ id, show, setShow }: any) => {
  return (
    <div className="rules-modal_">
      <div className="rules-modal-header_">
        <div className="game-name_">{titleById[id]}</div>
        <div className="close-action_">
          <span title="Info" className="fas fa-times" onClick={()=>setShow(!show)}/>
        </div>
      </div>
      <div className="rules-modal-body_">
        <div className="rules-modal-image_">
          <img alt="rules" src={`/img/${id}.webp`} />
        </div>
      </div>
    </div>
  )
}

export default RuleModals
