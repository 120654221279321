import React from "react"
import CardComp from "../CardComp/CardComp"

const Lucky7Card = ({ t1 }: any) => {
  return (
    <div className="card-shower_">
      <div className="runners_">
        <div className="runner_">
          <div className="runner-cards_">
            <div className="card-img-wrapper_ ">
              <CardComp shown={t1.C1 != "1"} card={t1.C1 || "1"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lucky7Card
