import type React from "react"

interface DT20Props {
  t1: any
  odds: any
  setBetState: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DT20 = ({ t1, odds, setBetState, setOpen }: DT20Props) => {
  const t2 = odds?.t2 || []

  const handleClick = (t2Data: any) => {
    setBetState &&
      setBetState((prev: any) => ({
        ...prev,
        nation: t2Data?.nation,
        casinoName: 2,
        isBack: true,
        odds: Number(t2Data?.rate),
        selectionId: t2Data?.sid,
        colorName: "back",
      }))
    setOpen(true)
  }
  return (
    <div className="dragon-tiger-odds-section_">
      <div className="dragon-tiger-odds-container_">
        <div className="dragon-tiger-odds-box_">
          <div className="odds-price_">{t2?.[0]?.rate}</div>
          <div
            className={`odds-label_ ${t2?.[0]?.gstatus == "0" ? "suspended" : ""}`}
            onClick={() => t2?.[2]?.gstatus != "0" && handleClick(t2?.[0])}
          >
            {" "}
            {t2?.[0]?.nation}
          </div>
          <div
            className={`odds-position_  ${t2?.[0]?.pnl >= 0 ? "plus" : "minus"}`}
          >
            {t2?.[0]?.pnl}
          </div>
        </div>
        <div className="dragon-tiger-odds-box_">
          <div className="odds-price_">{t2?.[2]?.rate}</div>
          <div
            className={`odds-label_ ${t2?.[2]?.gstatus == "0" ? "suspended" : ""}`}
            onClick={() => t2?.[2]?.gstatus != "0" && handleClick(t2?.[2])}
          >
            {" "}
            {t2?.[2]?.nation}
          </div>
          <div
            className={`odds-position_  ${t2?.[2]?.pnl >= 0 ? "plus" : "minus"}`}
          >
            {t2?.[2]?.pnl}
          </div>
        </div>
        <div className="dragon-tiger-odds-box_">
          <div className="odds-price_">{t2?.[1]?.rate}</div>
          <div
            className={`odds-label_ ${t2?.[1]?.gstatus == "0" ? "suspended" : ""}`}
            onClick={() => t2?.[2]?.gstatus != "0" && handleClick(t2?.[1])}
          >
            {" "}
            {t2?.[1]?.nation}
          </div>
          <div
            className={`odds-position_  ${t2?.[1]?.pnl >= 0 ? "plus" : "minus"}`}
          >
            {t2?.[1]?.pnl}
          </div>
        </div>
        {/**/}
      </div>
    </div>
  )
}

export default DT20
