import { Route, Routes } from "react-router-dom"
import { Suspense, lazy, useEffect } from "react"
import "./App.scss"
import CommonLodding from "./Component/CommonLodding"
import ProtectedRoute from "./Component/ProtectedRoute"
import NewLoginPage from "./Pages/Login/NewLoginPage"
import CasinoMainPage from "./Pages/Casino/CasinoMainPage"
import CasinoBets from "./Pages/Casino/casinoBets/CasinoBets"

const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"))
const Layout = lazy(() => import("./Component/Layout"))
const Inpaly = lazy(() => import("./Pages/InPlay/Inpaly"))
const TermsConditions = lazy(
  () => import("./Pages/TermsConditions/TermsConditions"),
)
const GameDeatils = lazy(() => import("./Pages/GameDetails/GameDeatils"))
const Complete = lazy(() => import("./Pages/Complete/Complete"))
const CricketResult = lazy(() => import("./Pages/Complete/CricketResult"))
const Ledger = lazy(() => import("./Pages/Ledger/Ledger"))
const Profile = lazy(() => import("./Pages/Profile/Profile"))
const ChangePassword = lazy(
  () => import("./Pages/ChangePassword/ChangePassword"),
)
const Casino = lazy(() => import("./Pages/Casino/Casino"))

const Loading = () => (
  <>
    <CommonLodding />
  </>
)

const App = () => {
  useEffect(() => {
    document.title = window.location.hostname?.split(".")[0]?.toUpperCase()
  }, [])
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<NewLoginPage />} />
          <Route path="/login" element={<NewLoginPage />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/tc" element={<TermsConditions />} />
            <Route path="/" element={<Layout />}>
              <Route path="/main" element={<Dashboard />} />
              <Route path="/inplay" element={<Inpaly />} />
              <Route path="/complete" element={<Complete />} />
              <Route path="/ledger" element={<Ledger />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/casino" element={<Casino />} />
              <Route path="/casino/:id" element={<CasinoMainPage />} />
              <Route path="/casino/casino-bet" element={<CasinoBets />} />
              <Route path="/password" element={<ChangePassword />} />
              <Route path="/cricket/:id" element={<GameDeatils />} />
              <Route path="/cricketResult/:id" element={<CricketResult />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  )
}

export default App
