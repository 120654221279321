import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    usedCoin: 0,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsedCoin: (state, action) => {
            state.usedCoin = action.payload || 0
        },
    },
})

export const { setUsedCoin } = userSlice.actions
export default userSlice.reducer
