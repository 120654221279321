import type React from "react"

interface TeenProps {
  t1: any
  odds: any
  setBetState: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TeenPatti = ({ t1, odds, setBetState, setOpen }: TeenProps) => {
  const t2 = odds?.t2 || []

  const handleClick = (t2: {
    nation: any
    rate: any
    b1: any
    mid: any
    sid: any
  }) => {
    setBetState &&
      setBetState((prev: any) => ({
        ...prev,
        nation: t2?.nation,
        casinoName: 2,
        isBack: true,
        odds: Number(t2?.rate) ,
        selectionId: t2?.sid,
        colorName: "back",
      }))
    setOpen(true)
   
  }


  return (
    <div className="teenpatti-odds-table_">
      <div className="odds-table-header_">
        <div className="table-header-left_">
          <div className="min-max_">
            Min : {t1?.min} || Max : {t1?.max}
          </div>
        </div>
        <div className="table-header-right_">
          <div className="label_">Back</div>
        </div>
      </div>
      <div className="odds-table-body_">
        <div className="odds-row_ ">
          <div className="runner-info_">
            <div className="runner_name">{t2[0]?.nation}</div>
            <div className={`odds-position_  ${t2?.[0]?.pnl>=0?"plus":"minus"}`}>{t2[0]?.pnl}</div>
          </div>
          <div className="odds-boxes_" onClick={() => t2[0]?.gstatus && handleClick(t2[0])}>
            <div className={`odds-box_ ${!t2[0]?.gstatus ? "suspended" : ""}`}>
              <div className="odds-rate_">{t2[0]?.rate}</div>
            </div>
          </div>
        </div>
        <div className="odds-row_ ">
          <div className="runner-info_">
            <div className="runner_name">{t2[2]?.nation}</div>
            <div className={`odds-position_  ${t2?.[2]?.pnl>=0?"plus":"minus"}`}>{t2[2]?.pnl}</div>
          </div>
          <div className="odds-boxes_" onClick={() =>t2[2]?.gstatus && handleClick(t2[2])}>
            <div className={`odds-box_ ${!t2[2]?.gstatus ? "suspended" : ""}`}>
              <div className="odds-rate_">{t2[2]?.rate}</div>
            </div>
          </div>
        </div>
        {/**/}
      </div>
    </div>
  )
}

export default TeenPatti
