import "./bets.scss"
import BackBtn from "../../../Component/BackBtn/BackBtn"
import { useGetCasinoMyBetQuery } from "../../../../store/service/userServices/userServices"
import CommonLodding from "../../../Component/CommonLodding"
import { useEffect, useState } from "react"

const CasinoBets = () => {
  const [totalStake, setTotalStake] = useState(0)
  const [totalPnl, setTotalPnl] = useState(0)
  const { data: CasinoBet, isLoading } = useGetCasinoMyBetQuery(
    {
      tableId: "",
      isGameCompleted: true,
      sportId: 5015,
    },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    const stakeSum = CasinoBet?.data?.reduce((acc, item) => acc + item.stake, 0)
    const pnlSum = CasinoBet?.data?.reduce((acc, item) => acc + item.pnl, 0)

    setTotalStake(stakeSum)
    setTotalPnl(pnlSum)
  }, [CasinoBet])

  return (
    <>
      {isLoading && <CommonLodding />}
      <BackBtn name="BACK TO CASINO MENU" to="/casino" />
      <table
        width="100%"
        border={0}
        cellSpacing={0}
        cellPadding={0}
        className="casino_bets"
      >
        <tbody>
          <tr>
            <td valign="top">
              <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              align="center"
                              className="TeamCombo"
                              style={{
                                background: "#3b394a",
                              }}
                            >
                              <p
                                style={{
                                  color: "#FFF",
                                  fontFamily: "Verdana, Geneva, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Casino Bets
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <section tabIndex={0} className="example-container mat-elevation-z8">
        <table
          mat-table=""
          className="mat-mdc-table mdc-data-table__table cdk-table custom-table"
          role="table"
        >
          <thead>
            <tr
              mat-header-row=""
              className="mat-mdc-header-row mdc-data-table__header-row cdk-header-row "
            >
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-game mat-column-game  mat-mdc-table-sticky mat-mdc-table-sticky-border-elem-left"
                style={{ left: 0, zIndex: 10, background: "#fff" }}
              >
                {" "}
                Game
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-roundId mat-column-roundId "
              >
                {" "}
                MarketId
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-amount mat-column-amount "
              >
                {" "}
                Amount
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-rate mat-column-rate "
              >
                {" "}
                Rate
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-mode mat-column-mode "
              >
                {" "}
                Mode
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-player mat-column-player "
              >
                {" "}
                Bet
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-result mat-column-result "
              >
                {" "}
                Result
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-pl mat-column-pl "
              >
                {" "}
                Porf/Loss
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-time mat-column-time "
              >
                {" "}
                Date
              </th>
            </tr>
            <tr
              mat-header-row=""
              className="mat-mdc-header-row mdc-data-table__header-row cdk-header-row "
            >
              <th
                role="columnheader"
                mat-header-cell=""
                colSpan={1}
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-secondaryHeader mat-column-secondaryHeader  mat-mdc-table-sticky mat-mdc-table-sticky-border-elem-right"
              >
                Total
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                colSpan={4}
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-secondaryHeaderAmount mat-column-secondaryHeaderAmount "
                style={{ fontWeight: "bold", color: "rgb(0, 0, 0)" }}
              >
                {totalStake}
              </th>
              <th
                role="columnheader"
                mat-header-cell=""
                colSpan={4}
                className="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-secondaryHeaderPL mat-column-secondaryHeaderPL "
                style={{
                  fontWeight: "bold",
                  color: totalPnl >= 0 ? "rgb(10, 10, 231)" : "red",
                }}
              >
                {totalPnl}
              </th>
            </tr>
          </thead>
          <tbody className="mdc-data-table__content">
            {CasinoBet?.data?.map(items => {
              return (
                <tr className="mat-mdc-row mdc-data-table__row cdk-row ">
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-game mat-column-game  mat-mdc-table-sticky mat-mdc-table-sticky-border-elem-left"
                    role="cell"
                    style={{ left: 0, zIndex: 10, background: "#fff" }}
                  >
                    {" "}
                    {items?.gameName}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-roundId mat-column-roundId "
                    role="cell"
                  >
                    {" "}
                    {items?.roundId}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-amount mat-column-amount "
                    role="cell"
                    style={{ fontWeight: "bold", color: "rgb(9, 9, 18)" }}
                  >
                    {" "}
                    {items?.stake}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-rate mat-column-rate "
                    role="cell"
                  >
                    {" "}
                    {items?.odds}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-mode mat-column-mode "
                    role="cell"
                    style={{
                      fontWeight: "bold",
                      color: items?.back ? "rgb(2, 2, 133)" : "red",
                    }}
                  >
                    {" "}
                    {items?.back ? "Back" : "Lay"}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-player mat-column-player "
                    role="cell"
                  >
                    {" "}
                    {items?.selectionName}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-result mat-column-result "
                    role="cell"
                  >
                    {" "}
                    {/* Player A */}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-pl mat-column-pl "
                    role="cell"
                    style={{ fontWeight: "bold", color: "rgb(10, 10, 231)" }}
                  >
                    {" "}
                    {items?.pnl}
                  </td>
                  <td
                    mat-cell=""
                    className="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-time mat-column-time "
                    role="cell"
                  >
                    {" "}
                    {items?.date}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>
    </>
  )
}

export default CasinoBets
